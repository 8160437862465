.drive-safe-module {
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 770px;
  position: relative;
}

.drive-safe-module img.forest-img {
  max-width: inherit;
  max-height: inherit;
  height: inherit;
  width: inherit;
  object-fit: cover;
}
@media (max-width: 600px) {
  .drive-safe-module img.forest-img {
    content: url("https://assets.cure.com/img/main-site/forest_road_mobile-2.webp");
  }
}

@media (min-width: 601px) and (max-width: 1300px) {
  .drive-safe-module img.forest-img {
    content: url("https://assets.cure.com/img/main-site/forest_road_1000.webp");
  }
}

@media (min-width: 1301px) and (max-width: 1500px) {
  .drive-safe-module img.forest-img {
    content: url("https://assets.cure.com/img/main-site/forest_road_1300.webp");
  }
}

@media (min-width: 1501px) {
  .drive-safe-module img.forest-img {
    content: url("https://assets.cure.com/img/main-site/forest_road_resized.webp");
  }
}
/**/
@media (max-width: 600px) {
  .show-your-support-module img.cars-img {
    content: url("https://assets.cure.com/img/main-site/webp/v1/cars_on_road_mobile.webp");
  }
}

@media (min-width: 601px) and (max-width: 1300px) {
  .show-your-support-module img.cars-img {
    content: url("https://assets.cure.com/img/main-site/cars_on_road_1000.webp");
  }
}

@media (min-width: 1301px) {
  .show-your-support-module img.cars-img {
    content: url("https://assets.cure.com/img/main-site/cars_on_road_1300.webp");
  }
}

.makes-us-better-module {
  overflow: hidden;
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  padding: 0 70px;
}

.blogs-module-mobile {
  display: none !important;
}

.makes-us-better-module .bg-img {
  max-width: inherit;
  max-height: inherit;
  height: inherit;
  width: inherit;
  object-fit: cover;
  position: absolute;
}

.show-your-support-module {
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.show-your-support-module img {
  max-width: inherit;
  max-height: inherit;
  height: inherit;
  width: inherit;
  object-fit: cover;
  position: absolute;
}

.blogs-module {
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 350px;
  padding: 0px;
}

.videoContainer {
  height: auto;
  width: 450px;
  margin-left: 0px !important;
}

.videoContainer :global .alice-carousel__prev-btn-item:hover,
.videoContainer :global .alice-carousel__next-btn-item:hover {
    color: #fff;
}

.videoContainer :global .alice-carousel__prev-btn-item {
    position: absolute;
    left: 0px;
    color: #fff;
    font-size: 32px;
    font-family: 'GlacialIndifference';
    top: 50%;
    transform: translateY(-50%);
}

.videoContainer :global .alice-carousel__next-btn-item {
    position: absolute;
    right: 0px;
    color: #fff;
    font-size: 32px;
    font-family: 'GlacialIndifference';
    top: 50%;
    transform: translateY(-50%);
}

.videoContainer :global .alice-carousel__dots-item:not(.__custom):hover,
.videoContainer :global .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #ff8000 !important;
    width: 12px;
    height: 12px;
}

.videoContainer :global .alice-carousel__dots-item:not(.__custom) {
    background-color: #fff;
    width: 12px;
    height: 12px;
}

.videoContainer :global .alice-carousel__next-btn [data-area]::after {
    content: url('https://assets.cure.com/img/main-site/right_arrow_.png');
}

.videoContainer :global .alice-carousel__prev-btn [data-area]::after {
    content: url('https://assets.cure.com/img/main-site/left_arrow_.png');
}

.videoContainer :global .alice-carousel__next-btn [data-area]:hover::after {
    content: url('https://assets.cure.com/img/main-site/right_arrow_org.png');
}

.videoContainer :global .alice-carousel__prev-btn [data-area]:hover:after {
    content: url('https://assets.cure.com/img/main-site/left_arrow_org.png');
}

.videoContainer :global .alice-carousel__dots-item:not(.__custom) {
  background-color: #e0e4fb;
}

.videoContainer :global .alice-carousel__dots {
  margin: 16px 3px 5px !important;
}

.videoContainer::before {
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: rgb(55, 105, 152);
}

.videoContainer .video-title {
  font-weight: 700;
  font-style: normal;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 18px;
  padding-left: 60px;
  margin-bottom: 0px;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-family: 'GlacialInDifference';
  padding-bottom: 0px;
  margin-top: 6px;
}

.blogs-module :global .alice-carousel {
  height: 100%;
}

.home-page .videoContainer :global .react-player iframe {
  height: 245px !important;
  padding-bottom: 0;
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 26px;
  width: 100% !important;
}

.support-service-container {
  z-index: 999;
  display: flex !important;
  align-items: center;
  flex-flow: row-reverse;
}

.blog-link {
  height: 350px;
}

.blog-link h3 {
  color: #294c7c !important;
  margin-top: 2px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blog-holder .content {
  font-weight: 400;
  font-style: normal;
  color: rgb(45, 44, 33);
  line-height: 1.5;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  text-decoration: none;
  font-family: GlacialIndifference;
  font-size: 20px;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  text-align: left;
  display: block;
  margin: 8px !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}

.blog-link .blog-holder {
  padding-top: 20px;
}

.blog-inner-container {
  height: 349px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 15%), 0 2px 2px rgb(0 0 0 / 15%), 0 4px 4px rgb(0 0 0 / 15%),
    0 8px 8px rgb(0 0 0 / 15%);
  background-color: rgb(238, 239, 243);
}

.blog-inner-left {
  margin-left: 8px;
}

.blog-inner-right {
  margin-left: 8px;
  margin-right: 8px;
}

.blog-link img {
  height: 280px;
  display: block;
  width: 264px;
  float: left;
  display: inline-block;
  margin-right: 12px;
  margin-left: 12px;
  margin-top: 7px;
  border: 10px solid #fff;
}

.makes-us-better-module h2 {
  z-index: 9;
  text-align: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 0px;
  height: 60px;
  font-family: raleway;
  font-weight: 400 !important;
}

.makes-us-better-module .sub-title {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: 'GlacialInDifference';
  color: rgb(51, 51, 51);
  z-index: 9;
}

.real-saving {
  content: url('https://assets.cure.com/img/main-site/referrals.png');
  width: 150px;
  height: 150px;
  display: block;
  margin: auto;
}
.real-time {
  content: url('https://assets.cure.com/img/main-site/roadside.png');
  width: 150px;
  height: 150px;
  display: block;
  margin: auto;
}
.real-experience {
  content: url('https://assets.cure.com/img/main-site/discounts.png');
  width: 150px;
  height: 150px;
  display: block;
  margin: auto;
}

.card-container {
  margin: auto;
  margin-bottom: 15px;
}

.block-element {
  display: block;
  width: 80% !important;
  margin: auto;
  text-align: center;
  font-size: 20px;
  line-height: 1.21;
  color: rgb(51, 51, 51);
  font-family: GlacialInDifference !important;
}

.card-title {
  text-decoration: none;
  display: block;
  text-align: center;
  margin-top: 12px;
}

.deserve-transparency {
  content: url('https://assets.cure.com/img/main-site/deserve-transparency.png');
}

.half-pregnant {
  content: url('https://assets.cure.com/img/main-site/half-pregnant.jpeg');
}

@media (min-width: 1500px) and (max-width: 1680px) {
  .home-page .videoContainer :global .react-player iframe {
    padding: 0 26px;
  }
  .videoContainer .video-title {
    padding-left: 32px;
    padding-bottom: 12px;
  }
}

@media (max-width: 1500px) {
  .videoContainer :global .alice-carousel__dots {
    position: initial;
    bottom: auto;
    left: 15%;
  }
}

@media (max-width: 1200px) {
  .videoContainer :global .alice-carousel__dots {
    margin: 0px 3px 5px !important;
    bottom: 5%;
    left: 10%;
  }
  .videoContainer .video-title {
    padding-bottom: 18px !important;
  }
}

@media (min-width: 1500px) and (max-width: 1680px) {
  .videoContainer :global .alice-carousel__dots {
    margin-top: 0px !important;
    position: initial;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .home-page .videoContainer :global .react-player iframe {
    padding: 0 26px;
    margin-top: 26px;
  }
  .videoContainer .video-title {
    padding-left: 26px;
  }
}

@media (min-width: 1113px) and (max-width: 1199px) {
  .home-page .videoContainer :global .react-player iframe {
    padding: 0 20px;
    margin-top: 26px;
  }
  .videoContainer .video-title {
    padding-left: 18px;
    font-size: 16px;
    padding-bottom: 36px;
  }
}

@media (max-width: 1112px) {
  .home-page .videoContainer :global .react-player iframe {
    padding: 0 16px;
    margin-top: 25px;
  }
  .videoContainer .video-title {
    padding-left: 18px;
    font-size: 16px;
    padding-bottom: 18px;
  }
  .videoContainer .video-title {
    max-width: 350px;
    bottom: auto;
    -webkit-line-clamp: inherit;
  }
}

@media (max-width: 1025px) {
  .blogs-module {
    display: none !important;
  }
}

@media (max-width: 600px) {
  .makes-us-better-module {
    padding: 0px 15px;
  }
  .real-saving {
    width: 100px;
    height: auto;
    margin: 0 15px 15px;
  }
  .real-time {
    width: 100px;
    height: auto;
    margin: 0 15px 15px;
  }
  .real-experience {
    width: 100px;
    height: auto;
    margin: 0 15px 15px;
  }
  .card-title {
    font-size: 18px !important;
    padding-top: 8px;
    margin-top: 12px;
  }
  .block-element {
    font-size: 16px;
    line-height: 1.21;
  }
  .GetAQuote .sub-title {
    margin-right: 10px;
  }
  .drive-safe-module {
    height: 556px;
  }
}

@media (max-width: 768px) {
  .real-saving {
    float: left;
    margin: 0 30px 30px;
  }
  .real-time {
    float: left;
    margin: 0 30px 30px;
  }
  .real-experience {
    float: left;
    margin: 0 30px 30px;
  }
  .card-title {
    font-size: 18px !important;
    padding-top: 0px;
    float: inherit;
    width: auto;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .block-element {
    text-align: left;
    font-size: 16px !important;
  }
  .inner-text-div {
    margin-top: 32px;
  }
}

@media (max-width: 541px) {
  .inner-text-div {
    margin-top: 0px;
  }
}

@media (max-width: 450px) {
  .blogs-module-mobile {
    display: block !important;
    width: 100%;
  }
  .blogs-module-mobile :global .alice-carousel__dots {
    margin: 0;
  }
  .blogs-module-mobile .videoContainer {
    height: 350px;
  }
  .blogs-module-mobile :global .alice-carousel {
    height: 100%;
  }
  .GetAQuote .sub-title {
    margin-bottom: 16px !important;
  }
  .GetAQuote .past-quote-container {
    padding-top: 10px;
  }
  .drive-safe-module {
    height: 600px;
  }
}

@media (max-width: 390px) {
  .home-page .card-container {
    margin-bottom: 0px !important;
  }

  .home-page .SupportBox .sub-text {
    margin-bottom: 15px !important;
  }
}

@media (max-width: 376px) {
  .videoContainer .video-title {
    padding-bottom: 12px;
  }
  .blogs-module-mobile .videoContainer {
    width: 375px;
  }
  .inner-text-div {
    margin-top: 0px;
  }
  .real-saving,
  .real-time,
  .real-experience {
    margin: 0 0px 0px;
    margin-right: 12px;
  }
  .home-page .card-container {
    margin-bottom: 15px !important;
  }
  .home-page .SupportBox .sub-text {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 360px) {
  .home-page h2 {
    height: 35px !important;
    font-size: 26px !important;
  }

  .home-page .SupportBox .cursive {
    height: 38px !important;
    font-size: 25px !important;
  }

  .home-page .card-container {
    margin-bottom: 15px !important;
  }

  .home-page .SupportBox .sub-text {
    margin-bottom: 0px !important;
  }
}
