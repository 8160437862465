.player-container {
  min-height: 246px;
}

.thumbnail-container {
  height: 246px;
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.playIcon {
  height: 42px;
  left: calc(50% - 30px);
  position: absolute;
  top: 130px;
  transition: all 0.3s ease-in-out;
  width: 60px;
  cursor: pointer;
} 

@media (min-width: 1681px) {
  .thumbnail-container {
    padding: 0 36px;
  }
}

@media (min-width: 1500px) and (max-width: 1680px) {
  .thumbnail-container {
    padding: 0 10px;
  }
}

@media (max-width: 1200px) {
  .thumbnail-container {
    margin: 25px 20px 0 20px;
  }
}

@media (min-width: 1201px) {
  .thumbnail-container {
    margin: 25px 25px 0 25px;
  }
}

/*
.videoInner {
  bottom: 0;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} 
*/